<template>
  <el-dialog title="新增" :visible.sync="dialogVisible" width="750px" :close-on-click-modal="false" class="br-dialog add-upd-dialog" @close="handleCancel">
    <el-form ref="Forms" :model="dialogFrom" :rules="rules" label-width="150px" :inline="true">
      <el-form-item class="br-form-item-label" label="核心企业名称：" prop="coreCmpName">
        <el-autocomplete
          v-model="dialogFrom.coreCmpName"
          clearable
          class="br-input-large"
          :fetch-suggestions="coreCmpNameSearch"
          placeholder="请输入核心企业名称"
          :trigger-on-focus="false"
          @select="coreCmpNameSelect"
        />
      </el-form-item>
      <el-form-item class="br-form-item-label" label="统一社会信用代码：" prop="coreCmpUnicode">
        <el-input v-model.trim="dialogFrom.coreCmpUnicode" class="br-input-large" disabled />
      </el-form-item>

      <el-form-item class="br-form-item-label" label="资金企业名称：" prop="fundCmpNameList">
        <el-select
          v-model="dialogFrom.fundCmpNameList"
          placeholder="请选择资金企业名称"
          filterable
          class="br-select-large"
          value-key="funderName"
          @change="fundCmpNameChange"
        >
          <el-option
            v-for="(item,index) in fundCmpList"
            :key="index"
            :value="item"
            :label="item.funderName"
          />
        </el-select>
      </el-form-item>
      <el-form-item class="br-form-item-label" label="统一社会信用代码：" prop="fundCmpUnicode">
        <el-input v-model.trim="dialogFrom.fundCmpUnicode" class="br-input-large" disabled />
      </el-form-item>
      <el-form-item class="br-form-item-label" label="所属银行类型：" prop="bankCode">
        <div style="width: 200px">
          {{ $codeNew(pushBankTypeList,dialogFrom.bankCode) }}
        </div>
      </el-form-item>

      <el-form-item class="br-form-item-label" label="商圈编码：" prop="fundBusiNo">
        <el-input v-model.trim="dialogFrom.fundBusiNo" class="br-input-large" />
      </el-form-item>
      <el-form-item class="br-form-item-label" label="资金方产品编码：" prop="fundProdNo">
        <el-input v-model.trim="dialogFrom.fundProdNo" class="br-input-large" />
      </el-form-item>

      <el-form-item class="br-form-item-label" label="付息方式：" prop="interstType">
        <el-select v-model="dialogFrom.interstType" placeholder="请选择付息方式" filterable class="br-select-large">
          <el-option
            v-for="(item,index) in intersTypeList"
            :key="index"
            :value="item.dictId"
            :label="item.dictName"
          />
        </el-select>
      </el-form-item>
      <el-form-item class="br-form-item-label" label="是否集团模式：" prop="groupState">
        <el-radio-group v-model="dialogFrom.groupState" class="br-radio-large">
          <el-radio :label="1">
            是
          </el-radio>
          <el-radio :label="0">
            否
          </el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item class="br-form-item-label" label="版本号：" prop="bankVersion">
        <el-select v-model="dialogFrom.bankVersion" placeholder="请选择版本号" filterable class="br-select-large">
          <el-option
            v-for="(item,index) in bankVersionList"
            :key="index"
            :value="item.dictId"
            :label="item.dictName"
          />
        </el-select>
      </el-form-item>
    </el-form>

    <div slot="footer" class="br-dialog-footer">
      <el-button class="br-btn-large br-cancel-btn" size="small" @click="handleCancel">
        取消
      </el-button>
      <el-button type="primary" class="br-btn-large br-submit-btn" size="small" :loading="btnloading" @click="handleSubmit('Forms')">
        提交
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
// getBankAndCoreList,
import { selectFundcmp, insertBusinessList } from '@/api/memberManagement'
import { getCmpListByCmpRoleList } from '@/api/tradeFinancing'
export default {
  props: {
    isShowDialog: {
      type: Boolean,
      default: () => (false)
    }
  },
  data() {
    return {
      btnloading: false,
      fundCmpList: [],
      dialogVisible: false,
      dialogFrom: {
        coreCmpName: '',
        coreCmpUnicode: '',
        fundCmpNameList: '',
        fundBusiNo: '',
        fundProdNo: '',
        state: 1,
        groupState: 0,
        interstType: '',
        bankVersion: ''
      },
      rules: {
        coreCmpName: [
          {
            required: true,
            trigger: ['change', 'blur'],
            message: '请输入核心企业名称'
          }
        ],
        coreCmpUnicode: [
          {
            required: true,
            trigger: ['change', 'blur'],
            message: '请输入统一社会信用代码'
          }, {
            pattern: /^[^_IOZSVa-z\W]{2}\d{6}[^_IOZSVa-z\W]{10}$/g,
            message: '请输入正确格式的统一社会信用代码',
            trigger: 'blur'
          }
        ],
        fundCmpNameList: [
          {
            required: true,
            trigger: ['change', 'blur'],
            message: '请选择资金企业名称'
          }
        ],
        fundBusiNo: [
          {
            required: true,
            trigger: ['change', 'blur'],
            message: '请输入商圈编码'
          }
        ],
        fundProdNo: [
          {
            required: true,
            trigger: ['change', 'blur'],
            message: '请输入资金方产品编码'
          }
        ],
        groupState: [
          {
            required: true,
            trigger: ['change'],
            message: '请选择是否集团模式'
          }
        ],
        interstType: [
          {
            required: true,
            trigger: ['change', 'blur'],
            message: '请选择付息方式'
          }
        ],
        bankVersion: [
          {
            required: true,
            trigger: ['change', 'blur'],
            message: '请选择版本号'
          }
        ]
      }
    }
  },
  computed: {
    pushBankTypeList() {
      return this.$store.getters.getDictionaryItem('PUSH_BANK_TYPE')
    },
    intersTypeList() {
      return this.$store.getters.getDictionaryItem('INTERS_TYPE')
    },
    bankVersionList() {
      return this.$store.getters.getDictionaryItem('BANK_VERSION')
    }
  },
  watch: {
    isShowDialog(newV) {
      this.dialogVisible = newV
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      // $store.getters.getDictionaryItem('SYS_TYPE')
      // 资金企业名称列表
      selectFundcmp(res => {
        this.fundCmpList = res.data
      })
    },
    // 核心企业名称模糊查询
    coreCmpNameSearch(queryString, cb, t) {
      const param = {
        cmpName: queryString,
        type: 'core',
        cmpUnicode: '',
        cmpRole: '11'
      }
      getCmpListByCmpRoleList(param, res => {
        if (res.code === 200) {
          const results = JSON.parse(JSON.stringify(res.data).replace(/cmpName/g, 'value'))
          if (results[0]) {
            this.dialogFrom.coreCmpName = queryString
            this.$forceUpdate()
          } else {
            this.dialogFrom.coreCmpUnicode = null
          }
          if (cb) cb(results)
        } else {
          this.$message({
            type: 'info',
            message: res.msg
          })
        }
      })
    },
    // 核心企业名称查询后选择回调
    coreCmpNameSelect(item) {
      this.dialogFrom.coreCmpUnicode = item.cmpUnicode
    },
    fundCmpNameChange(e) {
      if (e) {
        this.dialogFrom['fundCmpName'] = e.funderName
        this.dialogFrom['fundCmpUnicode'] = e.funderUnicode
        this.dialogFrom['bankCode'] = e.bankCode
        this.$forceUpdate()
      }
    },
    handleCancel() {
      this.$refs['Forms'].resetFields()
      this.$emit('handleCancel', false)
    },
    beforeClose(done) {
      this.$refs['Forms'].resetFields()
      this.$emit('handleCancel', false)
      done()
    },
    handleSubmit(Forms) {
      this.$refs.Forms.validate((valid) => {
        if (valid) {
          this.btnloading = true
          insertBusinessList(this.dialogFrom, res => {
            if (res.code === 200) {
              this.$message({
                type: 'success',
                message: '提交成功'
              })
              this.$emit('getdata')
              this.handleCancel()
            }
          }, () => {
            this.handleCancel()
          })
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
  .add-upd-dialog{
    .br-select-large{
      width: 534px;
    }
    .br-input-large {
      width: 534px;
    }
    .item-nobot {
      margin-bottom: 0;
    }
  }
</style>
